.list-component {
  display: flex;
  flex: 1;
}
.list-component .content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px !important;
  width: 100%;
}
.list-component .content .search {
  border-bottom: 1px solid #f4f4f4;
  border-top: 1px solid #f4f4f4;
}
.list-component .content .search input {
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  background: white;
  outline: none;
  width: 100%;
}
.list-component .content .wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.list-component .content .wrapper .header {
  position: sticky;
  background: white;
  top: 0px;
  display: flex;
  border-bottom: 1px solid #f4f4f4;
  padding: 0px 15px;
  z-index: 99;
}
.list-component .content .wrapper .header .prop {
  flex: 1;
  color: #b6b6b6;
  padding: 20px 5px;
  cursor: pointer;
}
.list-component .content .wrapper .header .prop.select {
  width: 30px;
  flex: 0 0 auto;
}
.list-component .content .wrapper .header .checkbox {
  padding: 0px 5px;
  flex: 0 0 auto;
  white-space: nowrap;
  overflow: hidden;
}
.list-component .content .wrapper .no-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  font-size: 2em;
}
.list-component .content .wrapper .loader-component {
  padding: 20px 0px;
}
.list-component .content .wrapper .list.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-columns: minmax(100px, auto);
  grid-gap: 20px;
}
.list-component .content .wrapper .list > .item {
  display: flex;
  align-items: center;
  padding: 15px 15px;
  cursor: pointer;
  color: black;
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
   supported by Chrome, Edge, Opera and Firefox */
}
.list-component .content .wrapper .list > .item .prop {
  padding: 0px 5px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-component .content .wrapper .list > .item .prop.select {
  width: 30px;
  flex: 0 0 auto;
}
.list-component .content .wrapper .list > .item .checkbox {
  padding: 0px 5px;
  flex: 0 0 auto;
  white-space: nowrap;
  overflow: hidden;
}
.list-component .content .wrapper .list > .item:hover {
  background-color: #F9F9F9;
}
.list-component .content .wrapper .list > .item.selected {
  background: #0099ff;
  color: white;
}
.list-component .content .wrapper .list > .item.selected * {
  color: white !important;
}
.list-component .content .wrapper .list > .item .context-menu {
  z-index: 9;
  min-width: 250px;
  position: absolute;
  background: white;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 32px 0px;
}
.list-component .content .wrapper .list > .item .context-menu > * + * {
  border-top: 1px solid #efefef;
}
.list-component .content .wrapper .list > .item .context-menu .action {
  padding: 10px;
  padding-left: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.list-component .content .wrapper .list > .item .context-menu .action.disabled {
  background: #F9F9F9;
  cursor: not-allowed;
}
.list-component .content .wrapper .list > .item .context-menu .action.disabled svg {
  fill: grey;
}
.list-component .content .wrapper .list > .item .context-menu .action.disabled .text {
  color: grey;
}
.list-component .content .wrapper .list > .item .context-menu .action.danger svg {
  fill: red;
}
.list-component .content .wrapper .list > .item .context-menu .action.danger .text {
  color: red;
}
.list-component .content .wrapper .list > .item .context-menu .action:hover {
  background-color: #F9F9F9;
}
.list-component .content .wrapper .list > .item .context-menu .action svg {
  width: 22px;
  height: 22px;
  margin-right: 15px;
}
.list-component .content .wrapper .list > .item .context-menu .action .text {
  flex: 1;
  color: black;
}